import { useUserStore } from '@st/user/stores/useUserStore'

export function useChangeNicknameForm() {
  const NICKNAME_REGEX = /^[a-zA-Z-_0-9]{3,25}$/

  const { t } = useI18n()
  const stFetch = useRawStFetch()
  const isLoading = ref(false)
  const userStore = useUserStore()
  const router = useRouter()
  const { open } = useToast()

  const nickname = ref('')
  watchEffect(() => {
    nickname.value = userStore.user?.nickname ?? ''
  })

  const errorMessage = ref()
  watch(nickname, () => {
    errorMessage.value = undefined
  })

  function validate() {
    return NICKNAME_REGEX.test(nickname.value)
      ? {
          isValid: true,
        }
      : {
          isValid: false,
          message: t('nicknameChange.nicknameRequirementViolation'),
        }
  }

  async function handleSubmit() {
    const { isValid, message } = validate()
    if (!isValid) {
      errorMessage.value = message
      return
    }

    isLoading.value = true
    try {
      await stFetch('/user/nickname/set', {
        method: 'post',
        body: {
          nickname: nickname.value,
        },
      })
      if (userStore.user) userStore.user.nickname = nickname.value
      router.replace({ query: {} })
      open({
        label: t('mobileProfile.changeNicknameSucсess'),
        type: 'positive',
      })
    } catch (error: any) {
      const errorCode = error?.data?.error

      errorMessage.value =
        errorCode === 'NICKNAME_REQUIREMENT_VIOLATION'
          ? t('nicknameChange.nicknameRequirementViolation')
          : t('nicknameChange.somethingWentWrong')
    } finally {
      isLoading.value = false
    }
  }

  return {
    nickname,
    handleSubmit,
    errorMessage,
    isLoading,
  }
}
